<template>
  <div class="announcement_warp">
    <van-overlay :show="appShow && apps.length > 0" z-index="2002">
      <div class="app_bg">
        <AdvSwiper :isShowIndicators="topList.length != 1" :advList="topList" v-if="topList.length > 0" class="topAdv" />
        <div class="applications">
          <div class="box">
            <div class="app_box">
              <div class="app_item" v-for="item in apps" :key="item.officialImg" @click="jumpApp(item)">
                <div class="icon">
                  <ImgDecypt :src="item.officialImg"></ImgDecypt>
                </div>
                <div class="download">下载</div>
              </div>
            </div>
          </div>
        </div>
        <div class="app_close" @click="jump"></div>
      </div>
    </van-overlay>
    <!-- <van-overlay :show="show" :lock-scroll="false" z-index="2001">
      <div v-if="listImg.length > 0" class="advertise_box">
        <div class="announcement_box_img" v-for="item in listImg" :key="item.id">
          <div class="content_img" @click="goAdv(item)">
            <ImgDecypt :src="item.cover"></ImgDecypt>
          </div>
        </div>
        <div class="imgBtn" @click="close('img')"></div>
      </div>
      <div v-if="list.length > 0">
        <div class="announcement_box" v-for="item in list" :key="item.id">
          <div class="content">
            {{ item.content }}
          </div>
          <div class="btn" @click="close">知道了~</div>
        </div>
      </div>
    </van-overlay> -->
    <van-overlay :show="show && list.length > 0" :lock-scroll="false" z-index="2001">
      <div>
        <div class="announcement_box" v-for="item in list" :key="item.id">
          <div class="content">
            {{ item.content }}
          </div>
          <div class="btn" @click="close">知道了~</div>
        </div>
      </div>
    </van-overlay>
    <van-overlay :show="show && listImg.length > 0 && list.length <= 0" :lock-scroll="false" z-index="2000">
      <div class="advertise_box">
        <div class="announcement_box_img" v-for="item in listImg" :key="item.id">
          <div class="content_img" @click="goAdv(item)">
            <ImgDecypt :src="item.cover"></ImgDecypt>
          </div>
        </div>
        <div class="imgBtn" @click="close('img')"></div>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import { Overlay } from 'vant';
import { getAnnouncementText, jumpAdv, getAdItem, getApplications, AdType } from '@/utils/getConfig';
import { getSessionItem, setSessionItem } from '@/utils/longStorage';
import AdvSwiper from '@/components/AdvSwiper';

export default {
  data() {
    return {
      list: [],
      listImg: [],
      show: false,
      appShow: false,
      apps: [],
    };
  },
  components: {
    [Overlay.name]: Overlay,
    ImgDecypt: () => import('@/components/ImgDecypt'),
    AdvSwiper,
  },
  created() {
    this.list = getAnnouncementText();
    this.listImg = getAdItem(9).reverse();
    this.apps = getApplications();
    this.topList = getAdItem(AdType.TOP_ADV_LIST);
    if (this.apps.length > 0 && getSessionItem('appFirstTime') != '1') {
      setSessionItem('appFirstTime', '1');
      this.appShow = true;
    }
    if (this.apps.length == 0) {
      if ((this.list.length > 0 || this.listImg.length > 0) && getSessionItem('firstTime') != '1') {
        setSessionItem('firstTime', '1');
        this.show = true;
      }
    }
  },
  methods: {
    close(param) {
      // console.log(1111);
      // console.log(this.list);
      // console.log(this.listImg);
      if (param === 'img') {
        this.listImg.pop();
        // console.log(22222);
      } else {
        this.list.pop();
        // console.log(this.list);
        // console.log(3333);
      }
      if (this.list.length == 0 && this.listImg.length == 0) {
        // console.log(444);
        this.show = false;
      }
    },
    goAdv(item) {
      jumpAdv(item);
    },
    jumpApp(item) {
      // window.open(item.officialUrl);
      jumpAdv(item);
    },
    jump() {
      this.appShow = false;
      if (this.apps.length > 0) {
        if ((this.list.length > 0 || this.listImg.length > 0) && getSessionItem('firstTime') != '1') {
          setSessionItem('firstTime', '1');
          this.show = true;
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.announcement_warp {
  .announcement_box {
    width: 288px;
    height: 410px;
    background: url('../../assets/png/announcement_bg.png') no-repeat;
    background-size: 100%;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #000000;

    .content {
      font-size: 14px;
      margin-top: 140px;
      margin-bottom: 15px;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 10px;
      height: 180px;
      color: #61656e;
      overflow-y: auto;
      white-space: pre-wrap;
      -webkit-overflow-scrolling: touch;
    }

    .btn {
      margin: 0 auto;
      width: 219px;
      height: 38px;
      background: linear-gradient(to right, rgb(247, 74, 130), rgb(252, 169, 132));
      border-radius: 10px;
      color: #ffffff;
      font-size: 14px;
      color: white;
      text-align: center;
      line-height: 38px;
      letter-spacing: 0.7px;
    }
  }

  .advertise_box {
    height: 470px;
    width: 360px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    .announcement_box_img {
      width: 300px;
      height: 400px;
      background-size: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: absolute;
      .content_img {
        width: 300px;
        height: 400px;
      }
    }
    .imgBtn {
      width: 40px;
      height: 40px;
      background: url('../../assets/png/close.png') 100%/100% no-repeat;
      position: absolute;
      bottom: 10px;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }

  .close {
    position: absolute;
    font-size: 34px;
    bottom: -90px;
    left: 50%;
    margin-left: -17px;
  }
  .app_bg {
    height: 100%;
    width: 100%;
    background-color: rgba(2, 4, 21, 0.55);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .topAdv {
      height: 88px;
      width: 329px;
      margin: 10px auto 19px;
      border-radius: 6px;
      /deep/ .adv-container {
        overflow: hidden;
        border-radius: 6px;
        height: 88px;
        transform: translateZ(0px);
        .my-swipe {
          height: 88px;
          .adv-swiper-slide {
            height: 88px !important;
            width: 334px !important;
          }
        }
        img {
          height: 100%;
        }
      }
      /deep/ img {
        height: 100%;
        object-fit: fill;
      }
    }
    .applications {
      .box {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .app_box {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          .app_item {
            margin: 0px 15px 24px;
            .icon {
              height: 92px;
              width: 92px;
              border-radius: 9px;
              // background-color: white;
              // 兼容ios的圆角问题
              transform: translateZ(0px);
              overflow: hidden;
            }
            .download {
              height: 26px;
              width: 89px;
              font-size: 14px;
              background: linear-gradient(to right, rgb(247, 74, 130), rgb(252, 169, 132));
              color: #ffffff;
              border-radius: 6px;
              margin-top: 12px;
              text-align: center;
              line-height: 26px;
            }
          }
        }
      }
    }
    .app_close {
      height: 38px;
      width: 38px;
      background: url('./../../assets/png/app_close.png') no-repeat;
      background-size: 100% 100%;
      margin-top: 20px;
    }
  }
}
</style>
