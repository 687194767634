<template>
  <div class="videoBoxSingle">
    <div class="coverBox">
      <ImgDecypt class="avatar" :src="videoInfo?.cover || videoInfo?.coverImg" />
      <div class="videoInfo flex-center-between">
        <div class="playCount flex-center-center">
          <svg-icon class="icon" iconClass="playIcon" />
          <div class="count">{{ videoInfo?.playCount | watchCountW }}</div>
        </div>
        <div class="playDuration">{{ videoInfo?.playTime | videotime }}</div>
      </div>
    </div>
    <div class="videoTitle">
      <div class="title">{{ videoInfo?.title ? videoInfo?.title : '无标题' }}</div>
    </div>
  </div>
</template>
<script>
import ImgDecypt from '@/components/ImgDecypt';

export default {
  name: 'videoBox',
  props: {
    videoInfo: {
      type: Object,
      required: true,
    },
  },
  components: {
    ImgDecypt,
  },
};
</script>
<style lang="scss" scoped>
.videoBoxSingle {
  height: 100%;
  width: 100%;
  // margin-bottom: 10px;
  .coverBox {
    height: 92px;
    border-radius: 10px 10px 0 0;
    overflow: hidden;
    position: relative;
    transform: translateZ(100px);
    .videoInfo {
      height: 24px;
      width: 100%;
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5));
      position: absolute;
      left: 0;
      bottom: 0;
      padding: 0 6px;
      box-sizing: border-box;
      transform: translateZ(100px);
      .playCount {
        .icon {
          height: 16px;
          width: 16px;
        }
        .count {
          font-size: 11px;
          color: white;
        }
      }
      .playDuration {
        font-size: 11px;
        color: white;
      }
    }
  }
  .videoTitle {
    background-color: rgb(248, 248, 249);
    border-radius: 0 0 10px 10px;
    padding: 4px 8px 8px;
    box-sizing: border-box;
    height: 25px;
    .title {
      font-size: 12px;
      color: rgb(65, 65, 65);
      font-weight: 500;
      line-height: 18px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
</style>
