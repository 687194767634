<!-- 竖排六宫格 -->
<template>
  <div class="verticalSixGrid">
    <div class="videoBoxSix">
      <VideoBox class="videoItem" @click.native="openVideo(item)" :videoInfo="item" v-for="item in videoInfoList" :key="item.id" />
    </div>
  </div>
</template>
<script>
import VideoBox from '@/components/VideoBox';

export default {
  components: {
    VideoBox,
  },
  props: {
    list: {
      type: Array,
      required: true,
    },
  },
  computed: {
    videoInfoList() {
      if (this.list) {
        return this.list.length > 6 ? this.list.slice(0, 6) : this.list;
      } else {
        return [];
      }
    },
  },
  methods: {
    openVideo(item) {
      if (item.playTime > 300) {
        this.$router.push({
          path: '/horizontalVideo',
          query: {
            id: item.id,
            sectionId: item.watch.isFreeWatch ? this.$route.query.id : '',
          },
        });
      } else {
        this.$store.commit('video/DELETE_JUMPVIDEOLIST');
        this.$store.commit('video/SET_JUMPVIDEOLIST', {
          list: this.list,
          videoInfo: item,
        });
        this.$router.push('/shortVideoDetails');
      }
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .videoBox .coverBox {
  height: 157px;
}
.verticalSixGrid {
  // display: grid;
  // grid-template-columns: 1fr 1fr;
  // grid-row-gap: 9px;
  // grid-column-gap: 7px;
  .videoBoxSix {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: 9px;
    grid-column-gap: 7px;
    .videoItem {
      width: 111px;
    }
  }
}
</style>
