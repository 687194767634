<!-- 大横屏无限制 -->
<template>
  <div class="bigHorizontalScreenUnlimit">
    <VideoBox @click.native="openVideo(item)" :videoInfo="item" v-for="item in list" :key="item.id" />
  </div>
</template>
<script>
import VideoBox from '@/components/VideoBox';

export default {
  components: {
    VideoBox,
  },
  props: {
    list: {
      type: Array,
      required: true,
    },
  },
  methods: {
    openVideo(item) {
      if (item.playTime > 300) {
        this.$router.push({
          path: '/horizontalVideo',
          query: {
            id: item.id,
            sectionId: item.watch.isFreeWatch ? this.$route.query.id : '',
          },
        });
      } else {
        this.$store.commit('video/DELETE_JUMPVIDEOLIST');
        this.$store.commit('video/SET_JUMPVIDEOLIST', {
          list: this.list,
          videoInfo: item,
        });
        this.$router.push('/shortVideoDetails');
      }
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .videoBox .coverBox {
  height: 195px;
}
</style>
