<template>
  <div class="homeItem">
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh" success-text="刷新成功" :class="{ 'my-refresh': isHigehtMax, none: refreshing }">
      <!-- 广告轮播 -->
      <AdvSwiper :advList="advList" class="advSwiper" v-if="advList.length > 0 && isFirst" />
      <!-- 公告 -->
      <!-- <div class="homeNoticeBox" v-if="isFirst && announceText">
        <svg-icon class="noticeIcon" iconClass="notice" />
        <van-notice-bar class="homeNotice" mode="closeable" :scrollable="true" :text="announceText" background="#f2f2f2" color="#000" :speed="60" />
      </div> -->
      <van-notice-bar
        mode="closeable"
        class="homeNoticeBox"
        :scrollable="true"
        :text="announceText"
        background="#f2f2f2"
        color="#000"
        speed="60"
        v-if="isFirst && announceText && showNotice"
        @close="closeNotice"
      >
        <template slot="left-icon">
          <div class="left_icon">
            <svg-icon iconClass="notice"></svg-icon>
            <span>公告：</span>
          </div>
        </template>
      </van-notice-bar>
      <!-- 金刚区 -->
      <div class="kingKong" v-if="isFirst" @touchstart.stop @touchmove.stop @touchend.stop>
        <div class="kingKongItem" v-for="(item, index) in kingKongList" :key="index" @click="jumpPage(item)">
          <ImgDecypt class="kingIcon" :src="item.icon" v-if="item.icon" />
          <div class="kingTitle">{{ item.title }}</div>
        </div>
      </div>
      <van-list
        v-if="!isNoData"
        v-model="loading"
        :finished="finished"
        finished-text="没有更多数据了"
        @load="onLoad"
        :offset="10"
        :error.sync="error"
        :immediate-check="false"
        error-text="请求失败，点击重新加载"
      >
        <div class="listBox" v-for="(item, index) in list" :key="item.sectionID">
          <div class="titleBox flex-center-between">
            <div class="title">{{ item.sectionName }}</div>
            <div class="moreIcon" @click="jumpMore(item)">
              <svg-icon class="moreIcon" iconClass="moreIcon" />
            </div>
          </div>
          <!-- 一大四小布局 -->
          <OneBigFourSmall :list="item.videoInfo" v-if="item.sortType == 0" />
          <!-- 四宫格(横) -->
          <FourGridHorizontal :list="item.videoInfo" v-else-if="item.sortType == 1" />
          <!-- 四宫格(竖) -->
          <FourGridVertical :list="item.videoInfo" v-else-if="item.sortType == 2" />
          <!-- 六宫格 -->
          <SixGrid :list="item.videoInfo" v-else-if="item.sortType == 3" />
          <!-- 横版滑动(小)布局 -->
          <HorizontalSlidingSmall :list="item.videoInfo" v-else-if="item.sortType == 4" />
          <!-- 横版滑动(大)布局 -->
          <HorizontalSlidingBig :list="item.videoInfo" v-else-if="item.sortType == 5" />
          <!-- 竖版滑动(大)布局 -->
          <VerticalSlideBig :list="item.videoInfo" v-else-if="item.sortType == 6" />
          <!-- 大横屏无限制 -->
          <BigHorizontalScreenUnlimit :list="item.videoInfo" v-else-if="item.sortType == 7" />
          <!-- 大横屏 + 横版滑动(小)布局 -->
          <BigScreenHorizontalSlidingSmall :list="item.videoInfo" v-else-if="item.sortType == 8" />
          <!-- 竖排六宫格 -->
          <VerticalSixGrid :list="item.videoInfo" v-else-if="item.sortType == 9" />
          <!-- 一大四小布局 -->
          <OneBigFourSmall :list="item.videoInfo" v-else />
          <!-- 广告 -->
          <ImgDecypt
            v-if="advsList.length > 0"
            class="advImg"
            :src="advsList[index % advsList.length].cover"
            @click.native="jumpPage(advsList[index % advsList.length])"
          />
        </div>
      </van-list>
      <NoData v-if="isNoData" />
    </van-pull-refresh>
  </div>
</template>
<script>
import { queryModulsList } from '@/api/movies';
import { AdType, getAdItem } from '@/utils/getConfig';
import { jumpAdv } from '@/utils/getConfig';
import { List, PullRefresh } from 'vant';
import NoData from '@/components/NoData';
import AdvSwiper from '@/components/AdvSwiper';
import ImgDecypt from '@/components/ImgDecypt';
import BigHorizontalScreenUnlimit from './BigHorizontalScreenUnlimit.vue';
import BigScreenHorizontalSlidingSmall from './BigScreenHorizontalSlidingSmall.vue';
import FourGridHorizontal from './FourGridHorizontal.vue';
import FourGridVertical from './FourGridVertical.vue';
import HorizontalSlidingBig from './HorizontalSlidingBig.vue';
import HorizontalSlidingSmall from './HorizontalSlidingSmall.vue';
import OneBigFourSmall from './OneBigFourSmall.vue';
import SixGrid from './SixGrid.vue';
import VerticalSixGrid from './VerticalSixGrid.vue';
import VerticalSlideBig from './VerticalSlideBig.vue';
import { getSessionItem, setSessionItem } from '@/utils/longStorage';
export default {
  name: 'HomeItem',
  components: {
    AdvSwiper,
    ImgDecypt,
    NoData,
    BigHorizontalScreenUnlimit,
    BigScreenHorizontalSlidingSmall,
    FourGridHorizontal,
    FourGridVertical,
    HorizontalSlidingBig,
    HorizontalSlidingSmall,
    OneBigFourSmall,
    SixGrid,
    VerticalSixGrid,
    VerticalSlideBig,
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
  },
  props: {
    announceText: {
      type: String,
      required: false,
    },
    moduleId: {
      type: String,
      required: true,
    },
    isFirst: {
      type: Boolean,
      required: true,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      kingKongList: [],
      list: [], // 列表
      pageNumber: 1, // 页码
      pageSize: 5, // 条数
      loading: true, // loading
      refreshing: false, // 刷新状态
      finished: false, // 下拉状态
      error: false, // 是否显示错误信息
      isNoData: false, // 暂无数据
      isHigehtMax: false,
      advList: [], // 广告列表
      advsList: [], // 专题之间广告列表
      showNotice: true, // 显示跑马灯
    };
  },
  created() {
    this.showNotice = getSessionItem('showNotice') === 'false' ? false : true;
    // console.log(this.showNotice)
    this.getList();
    this.kingKongList = this.$store.state.app.appConfig.quickLinkList; // 获取金刚区数据
    // if (quickLinkList && quickLinkList.length > 10) {
    //   this.kingKongList = quickLinkList.slice(0, 10);
    // }
    this.advList = getAdItem(AdType.HOME_ADV); // 获取轮播广告数据
    this.advsList = getAdItem(AdType.HOME_BETWEEN); // 获取专题之间的广告数据
  },
  methods: {
    // 查询列表
    async getList(type) {
      let req = {
        id: this.moduleId,
        data: {
          pageSize: String(this.pageSize),
          sectionSize: String(this.pageSize),
          sectionPage: String(this.pageNumber),
        },
      };
      try {
        let res = await this.$Api(queryModulsList, req);
        this.loading = false;
        this.refreshing = false;
        if (res.code === 200) {
          let list = res.data.sections || [];
          if (type === 'refresh') {
            this.list = list;
          } else {
            this.list = this.list.concat(list);
          }
          if (this.list.length <= 0) {
            this.isNoData = true;
          }
          if (!res.data.hasNext) {
            this.finished = true;
          }
        } else {
          this.error = true;
        }
      } catch (error) {
        this.loading = false;
        this.error = true;
      }
    },
    // 上拉加载
    onLoad() {
      this.loading = true;
      if (this.error) {
        this.error = false;
      } else {
        this.pageNumber++;
      }
      this.getList();
    },
    // 下拉刷新
    onRefresh() {
      this.pageNumber = 1;
      this.refreshing = true;
      this.finished = false;
      this.loading = true;
      this.getList('refresh');
    },
    jumpPage(item) {
      jumpAdv(item);
    },
    jumpMore(item) {
      this.$router.push({
        path: '/topicMore',
        query: {
          topicId: item.sectionID,
        },
      });
    },
    closeNotice() {
      setSessionItem('showNotice', 'false');
    },
  },
};
</script>
<style lang="scss" scoped>
.homeItem {
  width: 100%;
  height: calc(100vh - 116px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  .my-refresh {
    height: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
  .advSwiper {
    height: 139px;
    width: 334px;
    margin: 10px auto 19px;
    border-radius: 6px;
    /deep/ .adv-container {
      overflow: hidden;
      border-radius: 6px;
      height: 139px;
      transform: translateZ(0px);
      .my-swipe {
        height: 139px;
        .adv-swiper-slide {
          height: 139px !important;
          width: 334px !important;
        }
      }
      img {
        height: 100%;
      }
    }
    /deep/ img {
      height: 100%;
      object-fit: fill;
    }
  }
  :deep(.my-refresh) {
    padding: 0;
  }
  .homeNoticeBox {
    margin: 20px 14px 0;
    height: 38px;
    // position: relative;
    border-radius: 6px;
    // padding: 0 14px 0 39px !important;
    :deep(.van-notice-bar__content) {
      font-size: 12px;
    }
    .notice {
      left: 12px;
      top: 0;
      width: 24px;
      height: 24px;
    }
    .svg-icon {
      width: 17px;
      height: 20px;
      margin-right: 13.3px;
    }
    .left_icon {
      font-size: 12px;
      display: flex;
      align-items: center;
    }
    /deep/ .van-notice-bar__content {
      font-size: 12px;
    }
    // margin-top: 20px;
    .noticeIcon {
      position: absolute;
      top: 50%;
      left: 12px;
      z-index: 1;
      transform: translateY(-50%);
      width: 24px;
      height: 24px;
    }
  }
  .homeNotice {
    // position: relative;
    height: 38px;
    border-radius: 6px;
    padding: 0 14px 0 39px !important;
    :deep(.van-notice-bar__content) {
      font-size: 12px;
    }
    .notice {
      left: 12px;
      top: 0;
      width: 24px;
      height: 24px;
    }
  }
  .swiper {
    margin: 0 auto;
    border-radius: 10px;
    overflow: hidden;
    margin: 20px 14px 0;
  }
  .kingKong {
    // display: grid;
    // grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    // grid-row-gap: 13px;
    // grid-column-gap: 24px;
    // text-align: center;
    // font-size: 12px;
    // margin: 20px 14px 6px;
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    margin: 20px 0 0 14px;
    padding-left: 10px;
    .kingKongItem {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 24px;
      .kingIcon {
        width: 47px;
        height: 47px;
        margin-bottom: 8px;
        border-radius: 16px;
        overflow: hidden;
        transform: translateZ(100px);
      }
      .kingTitle {
        width: 47px;
        font-size: 11px;
        text-align: center;
      }
      :deep(.loading) {
        background: white !important;
      }
    }
  }
  .listBox {
    width: 100%;
    padding: 0 14px;
    box-sizing: border-box;
    // margin-bottom: 20px;
    .titleBox {
      font-size: 16px;
      color: rgb(121, 121, 121);
      font-weight: 600;
      margin: 10px 0;
      box-sizing: border-box;
      .moreIcon {
        height: 18px;
        width: 30px;
      }
    }
    .advImg {
      height: 72.3px;
      border-radius: 6px;
      overflow: hidden;
      margin-top: 20px;
    }
  }
}
</style>
