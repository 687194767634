<!-- 竖版滑动(大)布局 -->
<template>
  <div class="verticalSlideBig">
    <div class="slideBox" @touchstart.stop @touchmove.stop @touchend.stop>
      <VideoBox class="slideItem" @click.native="openVideo(item)" :videoInfo="item" v-for="item in list" :key="item.id" />
    </div>
  </div>
</template>
<script>
import VideoBox from '@/components/VideoBox';

export default {
  components: {
    VideoBox,
  },
  props: {
    list: {
      type: Array,
      required: true,
    },
  },
  methods: {
    openVideo(item) {
      if (item.playTime > 300) {
        this.$router.push({
          path: '/horizontalVideo',
          query: {
            id: item.id,
            sectionId: item.watch.isFreeWatch ? this.$route.query.id : '',
          },
        });
      } else {
        this.$store.commit('video/DELETE_JUMPVIDEOLIST');
        this.$store.commit('video/SET_JUMPVIDEOLIST', {
          list: this.list,
          videoInfo: item,
        });
        this.$router.push('/shortVideoDetails');
      }
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .videoBox .coverBox {
  height: 205px;
  width: 146px;
}
.verticalSlideBig {
  .slideBox {
    display: flex;
    overflow-x: auto;
    box-sizing: border-box;
    .slideItem {
      margin-right: 10px;
    }
  }
}
</style>
