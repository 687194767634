<!-- 一大四小布局 -->
<template>
  <div class="oneBigFourSmall">
    <VideoBoxSingle class="topBox" @click.native="openVideo(videoInfo)" :videoInfo="videoInfo" />
    <div class="fourGrid">
      <VideoBox class="videoBox" @click.native="openVideo(item)" :videoInfo="item" v-for="item in videoInfoList" :key="item.id" />
    </div>
  </div>
</template>
<script>
import VideoBoxSingle from '@/components/VideoBoxSingle';
import VideoBox from '@/components/VideoBox';

export default {
  components: {
    VideoBox,
    VideoBoxSingle,
  },
  props: {
    list: {
      type: Array,
      required: true,
    },
  },
  computed: {
    videoInfo() {
      if (this.list) {
        if (this.list.length > 1) {
          return this.list[0];
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    videoInfoList() {
      if (this.list) {
        return this.list.length >= 5 ? this.list.slice(1, 5) : this.list.slice(1, this.list.length);
      } else {
        return [];
      }
    },
  },
  methods: {
    openVideo(item) {
      if (item.playTime > 300) {
        this.$router.push({
          path: '/horizontalVideo',
          query: {
            id: item.id,
            sectionId: item.watch.isFreeWatch ? this.$route.query.id : '',
          },
        });
      } else {
        this.$store.commit('video/DELETE_JUMPVIDEOLIST');
        this.$store.commit('video/SET_JUMPVIDEOLIST', {
          list: this.list,
          videoInfo: item,
        });
        this.$router.push('/shortVideoDetails');
      }
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .videoBox .coverBox {
  height: 188px;
}
/deep/ .fourGrid .videoBox .coverBox {
  height: 94px;
  width: 168px;
}
/deep/ .videoBoxSingle .coverBox {
  height: 188px;
}
.oneBigFourSmall {
  .topBox {
    margin-bottom: 10px;
  }
  .fourGrid {
    // display: flex;
    // flex-wrap: wrap;
    // justify-content: space-between;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 9px;
    grid-column-gap: 7px;
    .videoBox {
      width: 168px;
    }
  }
}
</style>
