<!-- 大横屏 + 横版滑动(小)布局 -->
<template>
  <div class="bigScreenHorizontalSlidingSmall">
    <VideoBox @click.native="openVideo(videoInfo)" :videoInfo="videoInfo" />
    <div class="slideBox" @touchstart.stop @touchmove.stop @touchend.stop>
      <VideoBox class="slideItem" @click.native="openVideo(item)" :videoInfo="item" v-for="item in videoInfoList" :key="item.id" />
    </div>
  </div>
</template>
<script>
import VideoBox from '@/components/VideoBox';

export default {
  components: {
    VideoBox,
  },
  props: {
    list: {
      type: Array,
      required: true,
    },
  },
  computed: {
    videoInfo() {
      if (this.list) {
        if (this.list.length > 1) {
          return this.list[0];
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    videoInfoList() {
      if (this.list) {
        if (this.list.length > 1) {
          return this.list.slice(1, this.list.length);
        } else {
          return [];
        }
      } else {
        return [];
      }
    },
  },
  methods: {
    openVideo(item) {
      if (item.playTime > 300) {
        this.$router.push({
          path: '/horizontalVideo',
          query: {
            id: item.id,
            sectionId: item.watch.isFreeWatch ? this.$route.query.id : '',
          },
        });
      } else {
        this.$store.commit('video/DELETE_JUMPVIDEOLIST');
        this.$store.commit('video/SET_JUMPVIDEOLIST', {
          list: this.list,
          videoInfo: item,
        });
        this.$router.push('/shortVideoDetails');
      }
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .videoBox .coverBox {
  height: 188px;
}
/deep/ .slideBox .videoBox .coverBox {
  height: 94px;
  width: 147px;
}
.bigScreenHorizontalSlidingSmall {
  .slideBox {
    display: flex;
    overflow-x: auto;
    box-sizing: border-box;
    .slideItem {
      margin-right: 10px;
    }
  }
}
</style>
