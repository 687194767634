<template>
  <div class="home">
    <div class="searchBox">
      <div class="searchIcon" @click="$router.push('/search')"></div>
    </div>
    <!-- tab -->
    <van-tabs
      v-model="activeName"
      class="homeTab oepnX"
      title-active-color="rgb(0,0,0)"
      title-inactive-color="rgb(142,142,142)"
      color="#ff0d2c"
      animated
      swipeable
      :scrollspy="false"
      :lazy-render="true"
      @change="change"
    >
      <van-tab :title="item.subModuleName" v-for="(item, index) in tabList" :key="item.id">
        <HomeItem :announceText="announceText" :isFirst="index == 0" :moduleId="item.id" :key="index" />
      </van-tab>
    </van-tabs>
    <AnnouncementText />
  </div>
</template>
<script>
import { queryTabList } from '@/api/home';
import { getMarquee, MarqueeType } from '@/utils/getConfig';
import HomeItem from './components/HomeItem.vue';
import AnnouncementText from '@/components/AnnouncementText';
import { mapGetters } from 'vuex';
export default {
  name: 'home',
  components: {
    HomeItem,
    AnnouncementText,
  },
  computed: {
    ...mapGetters({
      homeScroll: 'homeScroll',
    }),
  },
  data() {
    return {
      activeName: '',
      oldActive: 0,
      announceText: '', // 跑马灯
      tabList: [], // tab列表
    };
  },
  created() {
    this.getTabList();
    this.getAnnounce();
  },
  methods: {
    change(index) {
      let dom = document.querySelectorAll('.homeItem');
      let scrollTopArr = this.homeScroll.scrollArr;
      if (this.oldActive) {
        let scrollTop0 = dom[index].scrollTop;

        if (scrollTop0 == 0) {
          // dom[index].scrollTop = scrollTopArr[index];
        }
        // globalStore.setScrollInfo(route.path, index);
        // globalStore.updateScroll(route.path, 'scrollArr', dom[index].scrollTop, index);
      } else {
        let scrollTop0 = dom[0].scrollTop;
        console.log(scrollTop0);
        scrollTopArr[0] = scrollTop0;
        this.$store.commit('app/SET_HOMESCLL', {
          className: 'homeItem',
          scrollTop: scrollTop0,
          isTab: true,
          scrollArr: scrollTopArr,
          curIndex: 0,
        });
        // console.log(this.homeScroll.scrollArr)
        if (scrollTop0 == 0) {
          dom[0].scrollTop = scrollTopArr[0];
        }
        // globalStore.setScrollInfo(route.path, 0);
        // globalStore.updateScroll(route.path, 'scrollArr', dom[0].scrollTop, 0);
      }
      setTimeout(() => {
        this.oldActive = index;
      }, 100);
    },
    async getTabList() {
      let res = await this.$Api(queryTabList, null);
      if (res.code == 200) {
        this.tabList = res.data;
      }
    },
    getAnnounce() {
      this.announceText = getMarquee(MarqueeType.community);
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
  height: 100%;
  .searchBox {
    position: absolute;
    right: 0;
    top: 2px;
    z-index: 1;
    width: 70px;
    background-color: white;
    .searchIcon {
      height: 40px;
      width: 40px;
      background: url('./../../assets/png/searchHome.png') no-repeat;
      background-size: 100% 100%;
      margin-left: 10px;
    }
  }
  .homeTab {
    position: relative;
    height: 100%;
  }
}
/deep/ .van-tabs__wrap {
  padding-right: 70px;
}
/deep/ .van-tabs {
  .van-tab__text {
    font-size: 14px;
  }
  .van-tab--active {
    font-weight: 600;
  }

  // .van-tab:last-child(2) {
  //   margin-right: 70px !important;
  // }
  .van-tabs__line {
    width: 14px;
    height: 6px;
    background: linear-gradient(to right, rgb(247, 74, 130), rgb(252, 169, 132));
    bottom: 20px;
  }
  // .van-tabs__wrap {
  //   overflow: auto;
  //   -webkit-overflow-scrolling: touch;
  // }
  // .van-tabs__wrap--scrollable {
  //   overflow-y: auto;
  //   -webkit-overflow-scrolling: touch;
  // }
  // .van-tabs__nav {
  //   overflow-y: auto;
  //   -webkit-overflow-scrolling: touch;
  // }
}
</style>
