import { render, staticRenderFns } from "./VerticalSlideBig.vue?vue&type=template&id=19b5447b&scoped=true&"
import script from "./VerticalSlideBig.vue?vue&type=script&lang=js&"
export * from "./VerticalSlideBig.vue?vue&type=script&lang=js&"
import style0 from "./VerticalSlideBig.vue?vue&type=style&index=0&id=19b5447b&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19b5447b",
  null
  
)

export default component.exports